@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Lato:200,300,400,600,700,800,900');
@import "reset.css";
@import "normalize-v8.css";

html {
    -webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

html, body {
    background-color: #FFF;
    font-family: 'Lato', sans-serif;
}

a {
    color:#42A5F5;
    text-decoration: none;
}

a:hover {
    color:rgb(41, 138, 212)
}

h1 {
    font-family: 'Ubuntu','Lato', sans-serif;
    font-size: 64px;
    font-weight: bold;
    line-height: 80px;
    letter-spacing: -0.5px;
    color: #08042b;
}

h2 {
    font-family: 'Ubuntu','Lato', sans-serif;
    font-size: 48px;
    font-weight: bold;
    line-height: 60px;
    letter-spacing: -0.3px;
}

h3 {
    font-family: 'Ubuntu','Lato', sans-serif;
    font-size: 42px;
    font-weight: bold;
    line-height: 54px;
    letter-spacing: -0.3px;
    color: #08042b;
}

h4 {
    font-family: 'Lato', sans-serif;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: -0.3px;
}

h5 {
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: normal;
}

h6 {
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: normal;
}

p {
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
}

div.section-desc a {
    text-decoration: underline;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-weight: normal;
    color: #2a91f5;
}

div.section-desc a:hover {
    color: #007af5;
}

::-ms-clear {
    display: none;
}
